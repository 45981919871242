import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FaBars } from 'react-icons/fa';
import Image from 'gatsby-image';

const Navbar = () => {
	const data = useStaticQuery(graphql`
		query NavbarQuery {
			site {
				siteMetadata {
					title
				}
			}
			logo: file(relativePath: { eq: "logos/logo_transparent.png" }) {
				childImageSharp {
					fixed(height: 35) {
						...GatsbyImageSharpFixed
					}
				}
			}
			socialLinks: allSocialsYaml {
				nodes {
					socials {
						link
						name
						icon {
							childImageSharp {
								fixed(height: 35) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
		}
	`);

	const { title } = data.site.siteMetadata;
	const [navbarOpen, setNavbarOpen] = useState(false);
	const { logo, socialLinks } = data;
	return (
		<nav className="relative flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
			<div className="container mx-auto flex flex-wrap items-center justify-between">
				<div className="w-full relative flex justify-between leading-normal lg:w-auto lg:static lg:block lg:justify-start">
					<Link
						className="text-lg font-bold leading-loose flex align-center whitespace-no-wrap uppercase text-gray-800"
						to="/"
					>
						<Image fixed={logo.childImageSharp.fixed} alt={'logo'} />
						<h2 className="ml-2">SidneyW</h2>
					</Link>
					<button
						className="text-gray-800 cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
						type="button"
						onClick={() => setNavbarOpen(!navbarOpen)}
					>
						<FaBars />
					</button>
				</div>
				<div className={'lg:flex flex-grow items-center' + (navbarOpen ? ' flex' : ' hidden')}>
					<ul className="flex lg:flex-row list-none lg:ml-auto space-x-4">
						{socialLinks.nodes[0].socials.map(props => (
							<SocialIcon {...props} key={props.name} />
						))}
					</ul>
				</div>
			</div>
		</nav>
	);
};

const SocialIcon = ({ name, link, icon }) => (
	<li className="nav-item">
		<a className="my-2 flex items-center clickable" href={link}>
			<Image fixed={icon.childImageSharp.fixed} className="rounded" alt={name} />
		</a>
	</li>
);

export default Navbar;
