import { graphql, Link, useStaticQuery, StaticQuery } from 'gatsby';
import React from 'react';
import '../styles/main.css';
import { ThemeContext } from '../theme';
import { FaBars, FaMedium, FaFacebookSquare } from 'react-icons/fa';
import Image from 'gatsby-image';
import Navbar from './nav';

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query LayoutQuery {
			site {
				siteMetadata {
					title
					author
					description
				}
			}
			socialImages: allFile(filter: { relativeDirectory: { eq: "social" } }) {
				edges {
					node {
						name
						childImageSharp {
							fixed(height: 30) {
								...GatsbyImageSharpFixed
							}
						}
					}
				}
			}
		}
	`);

	const { title, author, description } = data.site.siteMetadata;
	const { socialImages } = data;

	const LayoutHeader = () => {
		return (
			<header className="w-full fixed z-10 border-b border-smoke-200">
				<Navbar />
			</header>
		);
	};

	const LayoutFooter = () => {
		return (
			<footer className="mx-auto pt-16 pb-6 px-4 sm:px-6 lg:px-8">
				<p className="text-center">
					<span className="mx-2 text-base leading-6 text-smoke-600">{description}</span>
					<a href="/rss.xml" className="inline-flex h-4 w-4 md:h-3 md:w-3 text-smoke-600 hover:text-smoke-400">
						<svg className="w-full h-full" fill="currentColor" viewBox="0 0 24 24">
							<path d="M3.429 24a3.429 3.429 0 100-6.857 3.429 3.429 0 000 6.857zM24 24h-4.629C19.371 13.371 10.63 4.629 0 4.629V0c13.2 0 24 10.8 24 24z" />
							<path d="M15.943 24h-4.457c0-6.343-5.143-11.486-11.486-11.486V8.057c8.743 0 15.943 7.2 15.943 15.943z" />
						</svg>
					</a>
				</p>
				<p className="text-center pt-2 text-sm leading-6 text-smoke-400">
					&copy; {new Date().getFullYear()} {author}. All rights reserved.
				</p>
			</footer>
		);
	};

	return (
		<div className="min-h-screen w-full flex flex-col" style={{ backgroundColor: 'rgb(250, 250, 250)' }}>
			<LayoutHeader />
			<main className="container mx-auto flex-grow pt-24 px-4 sm:px-6 lg:px-0">{children}</main>
			<hr className="mt-16" />
			<LayoutFooter />
		</div>
	);
};

export default Layout;
